<template>
  <AppHeader />
  <NuxtPage class="page-content-grid" />
  <AppFooter />
</template>

<style lang="scss">
  .page-content-grid {
    min-height: 100%;
    display: grid;
    position: relative;
    background: $white;
    align-content: start;
    padding-top: calc(var(--logo-height) + calc(var(--topbar-padding-vertical) * 2));

    @include min-screen('medium') {
      grid-template-rows: auto 1fr;
      grid-template-columns: 3fr 2fr;
      grid-template-areas: "steps summary" "content summary";
    }
  }
</style>
